import React, { useState, useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';
import { View, ScreenSpinner, AdaptivityProvider, AppRoot } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';

import Home from './panels/Home';
import axios from "axios";
import {API_URL} from "./settings";

const App = () => {
	const [activePanel, setActivePanel] = useState('home');
	const [fetchedUser, setUser] = useState(null);
	const [popout, setPopout] = useState(<ScreenSpinner size='large' />);
	const [token, setToken] = useState(null);
	const [fetchedHybridizers, setHybridizers] = useState(null);
	const [opts, setOpts] = useState(null);


	useEffect(() => {
		bridge.subscribe(({ detail: { type, data }}) => {
			if (type === 'VKWebAppUpdateConfig') {
				const schemeAttribute = document.createAttribute('scheme');
				schemeAttribute.value = data.scheme ? data.scheme : 'client_light';
				document.body.attributes.setNamedItem(schemeAttribute);
			}
		});
		async function fetchData() {
			const user = await bridge.send('VKWebAppGetUserInfo');
			setUser(user);
			setPopout(null);
		}
		fetchData();
	}, []);

	const go = e => {
		setActivePanel(e.currentTarget.dataset.to);
	};

	useEffect(() => {
		async function fetchToken() {
			setToken('a3232b3c85af987b34093718bebc3c87e68d7d87');
			axios.defaults.headers.common = {Authorization: `Token a3232b3c85af987b34093718bebc3c87e68d7d87`};
			fetchHybridizers();
			/*axios.post(`${API_URL}/vkmini/`, {access_token: window.location.search})
				  .then(res => {
				  	console.log(res);
					if (res && res.data && res.data.key) {
						setToken(res.data.key)
						axios.defaults.headers.common = {Authorization: `Token ${res.data.key}`}
						fetchHybridizers();
						//setPopout(null);
					}
				  })*/
		}
		fetchToken();
	}, []);

	async function fetchHybridizers() {
		axios.get(`${API_URL}/hybridizers/?limit=500`)
		  .then(res => {
			if (res && res.data && res.data.results) {
				setHybridizers(res.data.results);
				setOpts(res.data.results.map(sel => ({value: sel.id, label: sel.name+(Boolean(sel.prefix)?' ('+([sel.prefix,sel.prefix2,sel.prefix3,sel.prefix4].filter(id=>Boolean(id)).join(', '))+')':'')})));
			}
		  })
	}

	return (
		<AdaptivityProvider>
			<AppRoot>
				<View activePanel={activePanel} popout={popout}>
					<Home id='home' fetchedUser={fetchedUser} fetchedHybridizers={fetchedHybridizers} go={go} token={token} opts={opts}/>
				</View>
			</AppRoot>
		</AdaptivityProvider>
	);
}

export default App;
