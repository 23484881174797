import React, {useEffect, useState} from 'react';
import { Panel, PanelHeader, PanelHeaderBack,
	Group, FormLayout, FormStatus, List,
	FormItem, Input, Select, Textarea, Button, Search, Cell, SimpleCell, FormLayoutGroup, Div } from '@vkontakte/vkui';
import axios from "axios";
import {API_URL} from "../settings";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import {Icon12Repost} from "@vkontakte/icons";
import Icon12Cancel from "@vkontakte/icons/dist/12/cancel";
import './Persik.css';

const Home = props => {
	const [sortName, setSortName] = useState('');
	const [sortDescription, setSortDescription] = useState('');
	const [sortYear, setSortYear] = useState('');
	const [sortSelectioner, setSortSelectioner] = useState(null);
	const [sortSelectionerPreview, setSortSelectionerPreview] = useState(null);
	const [errorState, setErrorState] = useState(null);
	const [errorText, setErrorText] = useState(null);
	const [filtered, setFiltered] = useState([]);
	const [similar, setSimilar] = useState([]);
	const [searchString, setSearch] = useState('');
	const [searchHybridizer, setSearchHybridizer] = useState('');
	const [refreshFlag, setRefreshFlag] = useState('');

	

	const sendData = function(e) {
		e.preventDefault();
		if (sortSelectioner && sortName && sortDescription) {
			var data = {};
			data['plant'] = 1;
			data['hybridizer'] = sortSelectioner.value;
			data['name'] = sortName;
			data['description'] = sortDescription;
			if (sortYear) data['year_sel'] = sortYear;

			axios.post(`${API_URL}/varieties/`, data)
				.then(res => {
					console.log(res);
					if (res.data && res.data.id) {
						setRefreshFlag(Math.random());
						setSortYear("");
						setSortName("");
						setSortDescription("");

					} else {
						setErrorText("Ошибка записи");
						setErrorState(true);
					}

				})
				.catch (e => {
					setErrorText("Невозможно сохранить такой сорт");
					setErrorState(true);
				})
		}
	};
	const cleanError = function() {
		setErrorState(false);
		setErrorText(null);
	}

	let isCooldown = false;
	useEffect(() => {
		if (!isCooldown) {
			isCooldown = true;
			if (props.token && (searchString.length>2 || searchString.length==0) && (searchHybridizer.length>2 || searchHybridizer.length==0) ) fetchVarieties();
			setTimeout(() => isCooldown = false, 800);
		}
	}, [props.token, searchString, searchHybridizer, refreshFlag]);

	async function fetchVarieties() {
		//axios.get(`${API_URL}/dfphotos/?search=` + this.search_selectioner + '&name=' + this.search_name + '&catalog=' + this.search_catalog)
		//axios.get(`${API_URL}/dfphotos/?search=`+searchHybridizer+`&name=` + searchString+'&catalog=Сенполии')
		axios.get(`${API_URL}/dfphotos/?search=`+searchHybridizer+`&name=` + searchString+'&catalog=Геснериевые')
			.then(res => {
				if (res && res.data && res.data.results) {
					setFiltered(res.data.results);
					console.log(res.data.results)
					//setPopout(null);
				}

			})
	}

	useEffect(() => {
			if (props.token) fetchSimilar();
	}, [props.token, sortName, refreshFlag]);

	async function fetchSimilar() {
		//axios.get(`${API_URL}/dfphotos/?search=` + this.search_selectioner + '&name=' + this.search_name + '&catalog=' + this.search_catalog)
		axios.get(`${API_URL}/varieties/similar/?name=`+ sortName)
			.then(res => {
				if (res && res.data && res.data.results) {
					setSimilar(res.data.results);
				}

			})
	}


	//console.log(props.opts);
	return (
		<Panel id="home">
			<Group>
				{props.opts && <FormLayout onSubmit={sendData}>
					<FormItem>
						{errorState && <FormStatus header="Ошибка" mode="error">
							{errorText}
						</FormStatus>}
					</FormItem>
					<FormLayoutGroup mode="horizontal">
						<FormItem><Div>{sortSelectionerPreview}</Div></FormItem>
						<FormItem status={sortSelectioner ? 'valid' : 'error'}>
							<Autocomplete
							  id="combo-box-demo"
							  options={props.opts}
							  getOptionLabel={(option) => option.label || ''}
							  getOptionSelected={(option) => option.label}
							  renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Выберите селекционера" error={sortSelectioner===null}/>}
							  value={sortSelectioner}
							  openOnFocus
							  onChange={(e, newValue) => {
								  setSortSelectioner(newValue);
								  console.log(newValue)
							  }}

							/>
						</FormItem>
						<FormItem></FormItem>
					</FormLayoutGroup>
					<FormLayoutGroup mode="horizontal">
						<FormItem>
							<Search value={searchHybridizer} onChange={e => setSearchHybridizer(e.target.value)} placeholder="сорт или селекционер"/>
							<List>
							{filtered && filtered.slice(0,5).map(variety=>
								<SimpleCell key={variety.id+'_'}
									onClick={e=>{
										setErrorState(false);
										setSortName(variety.name);
										setSortDescription(variety.description);
										setSortSelectionerPreview(variety.selectioner);
										setSortYear(null);
									}}
									after={<Button onClick={e=>{
										console.log(variety.id);
										e.stopPropagation();
										e.preventDefault();

										axios.patch(`${API_URL}/dfphotos/${variety.id}/`, { done: 'Y' })
											.then(res => {
												if (res.data && res.data.id) {
													setRefreshFlag(Math.random());
												} else {
													setErrorText("Ошибка записи");
													setErrorState(true);
												}

											})
											.catch (e => {
												setErrorText("Невозможно udalit сорт");
												setErrorState(true);
											})
									}}>
										<Icon12Cancel/>
									</Button>
									}
								>
									{variety.name}
								</SimpleCell>)
							}
							</List>

						</FormItem>
						<FormItem>
							<Div>
								<FormItem top="Название сорта" status={sortName ? 'valid' : 'error'} >
									<Input name="sortname" value={sortName} onChange={e => {setSortName(e.target.value); cleanError()}}/>
								</FormItem>
							</Div>
							<Div>
								<FormItem top="Описание сорта" status={sortDescription ? 'valid' : 'error'} >
									<Textarea name="sortdescription" value={sortDescription} onChange={e => setSortDescription(e.target.value)} />
								</FormItem>

							</Div>
							<Div>
								<FormItem top="Year" >
									<Input name="sortyear" value={sortYear} onChange={e => {setSortYear(e.target.value); cleanError()}}/>
								</FormItem>
							</Div>
						</FormItem>
						<FormItem top="Similar">
							{similar && similar.slice(0,3).map(variety=>
								<SimpleCell key={variety.name}
								after={variety.sim+"%"}
 								className={variety.sim>98?"urg":variety.sim>80?'yel':""}
								>
									{variety.name}
								</SimpleCell>)
							}
						</FormItem>
					</FormLayoutGroup>
					<FormItem>
						<Button size="l" stretched disabled={!sortName || !sortSelectioner || !sortDescription}>Сохранить</Button>
					</FormItem>






				</FormLayout>}
			</Group>


		</Panel>

	)

};

export default Home;